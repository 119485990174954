class BackgroundVideo extends HTMLElement {
  constructor() {
    super();

    this.video = this.querySelector("video");
    this.view = this.dataset.view;
    this.pauseButton = this.querySelector("background-video-pause-button");
    this.tracks = this.querySelectorAll("track");

    window.addEventListener("resize", () => {
      this.updateVideoPlayback();
    });

    this.video.addEventListener("canplay", () => {
      this.updateVideoPlayback();
      if (this.tracks.length) {
        this.tracks.forEach((track) => {
          if (track.srclang === Shopify.locale) {
            track.track.mode = "showing";
          } else {
            track.track.mode = "hidden";
          }
        });
      }
    });

    document.body.addEventListener("click", (event) => {
      if (this.video.paused) {
        this.updateVideoPlayback();
      }
    });
    document.body.addEventListener("touchstart", (event) => {
      if (this.video.paused) {
        this.updateVideoPlayback();
      }
    });

    this.muteBtn = this.parentElement.parentElement.querySelector(
      "button[aria-label='Mute']"
    );
    if (this.muteBtn) {
      if (
        (window.matchMedia("(max-width: 1023px)").matches &&
          this.view === "mobile") ||
        (window.matchMedia("(min-width: 1024px)").matches &&
          this.view === "desktop")
      ) {
        this.muteBtn.addEventListener("click", () => {
          if (this.video.muted) {
            this.video.muted = false;
            this.video.play();
            this.muteBtn.setAttribute("aria-label", "Unmute");
          } else {
            this.video.muted = true;
            this.video.play();
            this.muteBtn.setAttribute("aria-label", "Mute");
          }
        });
      }
    }
  }

  updateVideoPlayback() {
    if (this.view === "all" || this.view == null) {
      this.video.play();
      return;
    }
    if (window.matchMedia("(max-width: 1023px)").matches) {
      // It's a mobile viewport
      if (this.view === "mobile") {
        this.video.play();
      } else {
        this.video.pause();
      }
    } else {
      // It's a desktop viewport
      if (this.view === "desktop") {
        this.video.play();
      } else {
        this.video.pause();
      }
    }
  }

  pause() {
    this.video.pause();
    this.pauseButton.setAttribute("data-playing", "false");
    this.pauseButton.setAttribute(
      "aria-label",
      this.pauseButton.getAttribute("data-play")
    );
  }

  resume() {
    this.video.play();
    this.pauseButton.setAttribute("data-playing", "true");
    this.pauseButton.setAttribute(
      "aria-label",
      this.pauseButton.getAttribute("data-pause")
    );
  }
}

customElements.define("background-video", BackgroundVideo);

class BackgroundVideoPauseButton extends HTMLElement {
  constructor() {
    super();

    this.bgVideo = this.closest("background-video");

    this.addEventListener("click", () => {
      if (!this.bgVideo.video.paused) {
        this.bgVideo.pause();
      } else {
        this.bgVideo.resume();
      }
    });
  }
}

customElements.define(
  "background-video-pause-button",
  BackgroundVideoPauseButton
);
